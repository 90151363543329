import _omit from 'lodash/omit';
import React from 'react';

type HoverOnLeave = (() => void);

export type HoverProps = React.HTMLAttributes<HTMLDivElement> & {
	children?: React.ReactNode;
	flexColumn?: boolean;
	onHover: () => HoverOnLeave | void;
}

export default function Hover(props: HoverProps) {
	const onLeave = React.useRef<HoverOnLeave>();

	const onHover = () => {
		if (props.onHover) {
			onLeave.current = props.onHover() || undefined;
		}
	};

	const customProps = _omit(props, ['onHover', 'flexColumn']);

	if (props.flexColumn) {
		customProps.style = { display: 'flex', flexDirection: 'column' };
	}

	return (
		<div
			onMouseEnter={onHover}
			onMouseLeave={() => onLeave.current?.()}

			{...customProps}
		/>
	);
}