import { ApiCollection }                      from 'Collections/ApiCollection';
import { PagedCollection }                    from 'Collections/PagedCollection';
import CompanyModel                           from 'Models/directory/CompanyModel';
import EnterpriseModel                        from 'Models/directory/EnterpriseModel';
import EnterprisePartitionModel               from 'Models/directory/EnterprisePartitionModel';
import TaskZoneModel                          from 'Models/intervention/TaskZoneModel';
import BillableAggregatedDataModel            from 'Models/invoice/BillableAggregatedDataModel';
import BillableItemModel                      from 'Models/invoice/BillableItemModel';
import BillableModel                          from 'Models/invoice/BillableModel';
import BillableStatusModel                    from 'Models/invoice/BillableStatusModel';
import BillingGroupMetaModel                  from 'Models/invoice/BillingGroupMetaModel';
import BillingGroupModel                      from 'Models/invoice/BillingGroupModel';
import BillingOwnerModel                      from 'Models/invoice/BillingOwnerModel';
import BillingPeriodModel                     from 'Models/invoice/BillingPeriodModel';
import BillingPeriodStatusModel               from 'Models/invoice/BillingPeriodStatusModel';
import InvoicingGroupChannelNotificationModel from 'Models/invoice/InvoicingGroupChannelNotificationModel';
import InvoicingGroupEmailModel               from 'Models/invoice/InvoicingGroupEmailModel';
import ContractIterationModel                 from 'Models/sales/ContractIterationModel';
import ContractModel                          from 'Models/sales/ContractModel';
import QuotationModel                         from 'Models/sales/QuotationModel';
import { BILLABLE_STATUS_CREATED }            from 'constants/BillableStatuses';
import { makeObservable }                     from 'mobx';
import { observable }                         from 'mobx';
import { computed }                           from 'mobx';
import moment                                 from 'moment';
import AbstractModelXStore                    from 'stores/AbstractModelXStore';
import { appStore }                           from 'stores';
import { getIdFromUrn }                       from 'tools/UrnTools';
import notificationApiError                   from 'tools/notificationApiError';
import { notificationError }                  from 'tools/notification';

export default class BillingGroupListStore extends AbstractModelXStore {

	public billableAggregatedData = new BillableAggregatedDataModel();
	public billableCollection = new ApiCollection(BillableModel);
	public billableItemCollection = new ApiCollection(BillableItemModel);
	public billableStatusCollection = new ApiCollection(BillableStatusModel);
	public billingGroupMetaCollection = new ApiCollection(BillingGroupMetaModel);
	public billingGroupPagedCollection = new PagedCollection(BillingGroupModel);
	public billingOwnerCollection = new ApiCollection(BillingOwnerModel);
	public billingPeriod = new BillingPeriodModel();
	public billingPeriodStatusCollection = new ApiCollection(BillingPeriodStatusModel);
	public companyCollection = new ApiCollection(CompanyModel);
	public contractCollection = new ApiCollection(ContractModel);
	public contractIterationCollection = new ApiCollection(ContractIterationModel);
	public enterpriseCollection = new ApiCollection(EnterpriseModel);
	public enterprisePartitionCollection = new ApiCollection(EnterprisePartitionModel);
	public invoicingGroupChannelNotificationCollection = new ApiCollection(InvoicingGroupChannelNotificationModel);
	public invoicingGroupEmailCollection = new ApiCollection(InvoicingGroupEmailModel);
	public quotationCollection = new ApiCollection(QuotationModel);
	public statBillableCollection = new ApiCollection(BillableModel);
	public statBillingGroupContractIterationCollection = new PagedCollection(BillingGroupModel);
	public statBillingGroupQuotationCollection = new PagedCollection(BillingGroupModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);

	private _billingPeriodId;

	@observable
	private _endDate;

	@observable
	private _selectedBillablesIris: string[] = [];

	@observable
	private _startDate;

	public constructor() {
		super();
		makeObservable(this);
	}

	@computed
	public get billableItemByBillableId() {
		return this.billableItemCollection.groupBy('billableId');
	}

	@computed
	public get billablesByBillingGroupId() {
		return this.billableCollection.groupBy('billingGroupId');
	}

	@computed
	public get endDate() {
		return this._endDate;
	}

	@computed
	public get selectedBillablesIris() {
		return this._selectedBillablesIris;
	}

	@computed
	public get startDate() {
		return this._startDate;
	}

	@computed
	public get taskZonesById() {
		return this.taskZoneCollection.indexBy('id');
	}

	public fetchData = async (
		filters = {},
		page: number | undefined = undefined,
		pageSize: number | undefined = undefined,
	) => {
		
		this.billingPeriod.setId(this._billingPeriodId);

		await Promise
			.all([
				this.billableStatusCollection.list({ cache: 3600 }),
				this.billingPeriod.fetch(),
				this.billingPeriodStatusCollection.list({ cache: 3600 }),
			])
			.catch(error => {
				notificationApiError(error);
				return;
			});

		const billingPeriodStatus = this.billingPeriodStatusCollection
			.find(billingPeriodStatus => billingPeriodStatus.id === this.billingPeriod.getId('billingPeriodStatus'));
		

		if (!billingPeriodStatus || billingPeriodStatus.reference !== 'open') {
			notificationError('Status introuvable ou période clôturée');
			return;
		}

		if (!this._startDate) {
			this._startDate = moment(this.billingPeriod.startDate);
		}

		if (!this._endDate) {
			const today = moment();
			const lastDayOfCurrentBillingPeriod = moment(this.billingPeriod.startDate).clone().endOf('month');
			this._endDate = today.isBefore(lastDayOfCurrentBillingPeriod) ? today : lastDayOfCurrentBillingPeriod;
		}

		const invoiceableAtBeforeStr = this._endDate.format('YYYY-MM-DD');


		this.billingGroupPagedCollection.setFilters({
			...filters,
			'billables.billableStatus.reference': BILLABLE_STATUS_CREATED.reference,
			'billables.invoiceableAt[before]': invoiceableAtBeforeStr,
			'billingOwner.ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
		});

		if (typeof page === 'number') {
			this.billingGroupPagedCollection.setPage(page);
		}

		if (typeof pageSize === 'number') {
			this.billingGroupPagedCollection.setItemsPerPage(pageSize);
		}

		this.billingGroupPagedCollection
			.list()
			.then(async () => {
				const billingGroupsIris = this.billingGroupPagedCollection.distinctDefinedKey('iri');

				if (billingGroupsIris.length < 1) {
					return;
				}

				this.billingOwnerCollection
					.listBy(this.billingGroupPagedCollection.map(m => m.getId('billingOwner')))
					.then(async () => {
						await Promise.all([
							this.companyCollection.listByFromCollection(this.billingOwnerCollection, 'companyId'),

							this.enterprisePartitionCollection
								.listByFromCollection(this.billingOwnerCollection, 'enterprisePartitionId')
								.then(async () => {
									await this.enterpriseCollection
										.listBy(this.enterprisePartitionCollection.map(m => m.getId('enterprise')));
								}),
						]);
					});

				this.billableCollection
					.setFilters({
						'billableStatus.reference': BILLABLE_STATUS_CREATED.reference,
						'invoiceableAt[before]': invoiceableAtBeforeStr,
					})
					.listBy(billingGroupsIris, 'billingGroup')
					.then(async () => {
						await this.billableItemCollection.listByFromCollection(this.billableCollection, 'id', 'billable');
						await this.taskZoneCollection.listByFromCollection(this.billableItemCollection, 'taskZoneId', 'id');
					});

				this.billingGroupMetaCollection
					.listBy(billingGroupsIris, 'billingGroup')
					.then(() => {
						const contractIterationsIdsObject = {};
						const quotationsIdsObject = {};

						this.billingGroupMetaCollection.forEach(billingGroupMeta => {
							if (billingGroupMeta.reference === 'contract_iteration_urn') {
								contractIterationsIdsObject[getIdFromUrn(billingGroupMeta.value)] = true;
							} else if (billingGroupMeta.reference === 'quotation_urn') {
								quotationsIdsObject[getIdFromUrn(billingGroupMeta.value)] = true;
							}
						});

						const contractIterationsIds = Object.keys(contractIterationsIdsObject);
						const quotationsIds = Object.keys(quotationsIdsObject);

						this.contractIterationCollection
							.listBy(contractIterationsIds)
							.then(() => {
								return this.contractCollection
									.listByFromCollection(this.contractIterationCollection, 'contractId', 'id');
							});

						this.quotationCollection.listBy(quotationsIds);
					});


				this.invoicingGroupChannelNotificationCollection
					.listBy(this.billingGroupPagedCollection.map(bg => bg.getId('invoicingGroup')), 'invoicingGroup');
					
				this.invoicingGroupEmailCollection
					.listBy(this.billingGroupPagedCollection.map(bg => bg.getId('invoicingGroup')), 'invoicingGroup');
			});

		this._fetchStatCollection();
	};

	public reloadData = () => {
		this.fetchData(this.billingGroupPagedCollection.getFilters(), this.billingGroupPagedCollection.page, this.billingGroupPagedCollection.itemsPerPage);
	};

	public async setBillingPeriodId(billingPeriodId, filters) {
		this._billingPeriodId = billingPeriodId;
		this._startDate = null;
		this._endDate = null;
		await this.fetchData(filters);
	}

	public setEndDate(endDate) {
		this._endDate = endDate;
	}

	public setSelectedBillablesIris = (selectedBillablesIris: string[] = []) => {
		this._selectedBillablesIris = selectedBillablesIris;
	};

	public setStartDate(startDate) {
		this._startDate = startDate;
	}

	private _fetchStatCollection = () => {
		const invoiceableAtBeforeStr = this._endDate.format('YYYY-MM-DD');

		const billableSharedFilters: ModelFilters<BillableModel> = {
			'billableStatus.reference': BILLABLE_STATUS_CREATED.reference,
			'billingGroup.billingOwner.ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
			'invoiceableAt[before]': invoiceableAtBeforeStr,
		};

		this.statBillableCollection.setFilters({ ...billableSharedFilters, properties: ['id'] }).list();

		this.billableAggregatedData.fetch({ params: billableSharedFilters, });

		const billingGroupSharedFilters: ModelFilters<BillingGroupModel> = {
			'billables.billableStatus.reference': BILLABLE_STATUS_CREATED.reference,
			'billables.invoiceableAt[before]': invoiceableAtBeforeStr,
			'billingOwner.ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
			'properties': ['id'],
		};

		this.statBillingGroupContractIterationCollection.setItemsPerPage(1).setPage(1);
		this.statBillingGroupContractIterationCollection.setFilters({
			...billingGroupSharedFilters,
			'billingGroupMetas.reference': 'contract_iteration_urn',
		});
		this.statBillingGroupContractIterationCollection.list();

		this.statBillingGroupQuotationCollection.setItemsPerPage(1).setPage(1);
		this.statBillingGroupQuotationCollection.setFilters({
			...billingGroupSharedFilters,
			'billingGroupMetas.reference': 'quotation_urn',
		});
		this.statBillingGroupQuotationCollection.list();
	};
}
