import FileStatusModel         from 'Models/file/FileStatusModel';
import MimeTypeModel           from 'Models/file/MimeTypeModel';
import TypeSourceModel         from 'Models/file/TypeSourceModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import displayFile             from 'tools/displayFile';
import downloadUrl             from 'tools/downloadUrl';
import { whenAsync }           from 'tools/modelxTools';
import FilePrivateApiModel     from '../../abstracts/FilePrivateApiModel';
import SourceModel             from './SourceModel';

@model.staticLabel('Fichier')
@model.urnResource('file')
@doc.path('/stonecode_file_files/{?id}')
export default class FileModel extends Blamable(Timestampable(FilePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'createdBy': id;
		'fileStatus': id;
		'fileStatus.reference': FileFileStatusReference;
		'typeSource': id;
		'typeSource.source': id;
		'typeSource.source.entityUrn': string;
		'typeSource.type': id;
		'typeSource.type.reference': FileTypeReference;
		'typeSource.type.service': id;
		'typeSource.type.service.serviceUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.date declare date: Moment;
	@doc.string declare file: string;
	@doc.model(FileStatusModel) declare fileStatus: FileStatusModel;
	@doc.string declare label: string;
	@doc.model(MimeTypeModel) declare mimeType: MimeTypeModel;
	@doc.string declare name: string;
	@doc.string declare description: string;
	@doc.number declare size: number;
	@doc.model(TypeSourceModel) declare typeSource: TypeSourceModel;

	public async download(name?: string) {
		await downloadUrl(this.downloadPath, name || this.name);
	}

	public async display(name?: string) {
		await whenAsync(() => this.mimeType.isLoaded);
		await displayFile(this.downloadPath, this.mimeType.reference, name || this.name);
	}

	public get downloadPath() {
		return (this.isLoaded && !!this.id) ? this.get('@url') : '';
	}

	public get isImage() {
		return this.mimeType.reference.startsWith('image/');
	}

	public get typeSourceId(): id {
		return this.get('typeSource.id');
	}

	public get source(): SourceModel {
		return this.get('typeSource.source');
	}

	public get isPublic() {
		return !!this.get('public');
	}
}
