import CommentModel         from 'Models/comment/CommentModel';
import OriginModel          from 'Models/sales/OriginModel';
import QuotationModel       from 'Models/sales/QuotationModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from '../../../../helpers/traits';
import { Blamable }         from '../../../../helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel(`Origine de l'offre`, false)
@model.urnResource('quotation_origin')
@doc.path('/quotation_origins/{?id}')
export default class QuotationOriginModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'quotation': id,
		'origin': id,
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.model(QuotationModel) declare quotation: QuotationModel;
	@doc.model(OriginModel) declare origin: OriginModel;

	@model.commentResolvable('quotation_origin_details')
	public declare details: CommentModel;
}