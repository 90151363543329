import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('RecurringMode')
@model.urnResource('recurring_mode')
@doc.path('/recurring_modes/{?id}')
export default class RecurringModeModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SalesRecurringModeReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesRecurringModeReference;
}