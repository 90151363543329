import CommentModel         from 'Models/comment/CommentModel';
import ContractModel        from 'Models/sales/ContractModel';
import OriginModel          from 'Models/sales/OriginModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from '../../../../helpers/traits';
import { Blamable }         from '../../../../helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel(`Origine de l'offre`, false)
@model.urnResource('contract_origin')
@doc.path('/contract_origins/{?id}')
export default class ContractOriginModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contract': id,
		'origin': id,
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.model(ContractModel) declare contract: ContractModel;
	@doc.model(OriginModel) declare origin: OriginModel;

	@model.commentResolvable('contract_origin_details')
	public declare details: CommentModel;
}