// eslint-disable-next-line max-classes-per-file
import { BorderOutlined }        from '@ant-design/icons';
import { ReloadOutlined }        from '@ant-design/icons';
import { SettingOutlined }       from '@ant-design/icons';
import { CheckSquareOutlined }   from '@ant-design/icons';
import { ModelClass }            from '@mathquis/modelx/lib/types/collection';
import * as Sentry               from '@sentry/react';
import { AbstractApiCollection } from 'Collections/AbstractApiCollection';
import ApiCollection             from 'Collections/ApiCollection';
import { PagedCollection }       from 'Collections/PagedCollection';
import Empty                     from 'antd/lib/empty';
import Space                     from 'antd/lib/space';
import { ColumnType }            from 'antd/lib/table';
import Typography                from 'antd/lib/typography';
import { Result }                from 'antd';
import ButtonEllipsis            from 'components/ButtonEllipsis';
import Form                      from 'components/Form';
import GridInfo                  from 'components/GridInfo/GridInfo';
import { IGridInfoItem }         from 'components/GridInfo/GridInfo';
import { PageListData }          from 'components/PagesList/PageList';
import { IPageListProps }        from 'components/PagesList/PageList';
import PageList                  from 'components/PagesList/PageList';
import { ValueType }             from 'components/Value';
import Value                     from 'components/Value';
import View                      from 'components/View';
import _merge                    from 'lodash/merge';
import _sortBy                   from 'lodash/sortBy';
import { observer }              from 'mobx-react';
import { reaction }              from 'mobx';
import AAM                       from 'modelx/models/abstracts/AbstractApiModel';
import { matchPath }             from 'react-router-dom';
import { CSSProperties }         from 'react';
import React                     from 'react';
import routes                    from 'routes/privates';
import InputFilters              from './InputFilters';

export interface IPageListCollectionProps<T extends AAM> extends Pick<IPageListProps,
	'className'
	| 'defaultFilters'
	| 'defaultPageSize'
	| 'defaultSortWay'
	| 'disableParamsFromLocation'
	| 'disableFetchOnMount'
	| 'displayCountFilters'
	| 'headerDisabled'
	| 'height'
	| 'name'
	| 'onResetFiltersCallback'
	| 'onRowSelectedCallback'
	| 'paginationDisabled'
	| 'paginationSimple'
	| 'renderSearchComponent'
	| 'renderSidebarComponent'
	| 'renderSubTopComponent'
	| 'renderTopComponent'
	| 'renderTopLeftComponent'
	| 'renderTopListComponent'
	| 'renderTopRightComponent'
	| 'requesterMode'
	| 'rowSelectionEnabled'
	| 'sizeChangerHidden'> {

	beforeFetch?: (collection: AbstractApiCollection<T>, pageListData: PageListData) =>
		void | boolean | Promise<void | boolean> | AbstractApiCollection<T> | Promise<AbstractApiCollection<T>>;
	collection: ApiCollection<T> | PagedCollection<T>;
	columnActions?: (m: T, pageList: PageList) => React.ComponentProps<typeof ButtonEllipsis>['actions'];
	columns: PageListColumn<T>[];
	defaultSortName?: ModelSortName<T>;
	hidden?: (model: T) => (column: IGridInfoItem) => boolean;
	hideColumns?: string[];
	hideFilters?: string[] | true;
	hideShortFilters?: boolean;
	keepFilters?: boolean;
	keepSorts?: boolean;
	loading?: boolean;
	mode?: 'vertical';
	onApplyFilters?: () => void;
	onFetchSuccess?: (collection: AbstractApiCollection<T>, pageListData: PageListData) => void;
	reloadOn?: (collection: AbstractApiCollection<T>) => boolean;
	saveDisabled?: boolean;
	sidebarFilters?: ISidebarFilter<T>[];
	tableProps?: IPageListProps['tableProps'];
	topRightActions?: (d: PageListData, pageList: PageList) => React.ComponentProps<typeof ButtonEllipsis>['actions'];
}

export interface ISidebarFilterWithoutShort {
	label: string;
	render: (d: PageListData) => React.ReactNode;
	renderShort?: true | ((d: PageListData) => React.ReactNode);
}

export interface ISidebarFilterWithShort {
	label?: string;
	render?: (d: PageListData) => React.ReactNode;
	renderShort: true | ((d: PageListData) => React.ReactNode);
}

export type ISidebarFilter<T extends AAM> = {
	defaultValue?: string;
	name: string;
	transform: ModelFilterName<T> | ((value, coll: AbstractApiCollection<T>, filters: Filters) => void);
} & (ISidebarFilterWithoutShort | ISidebarFilterWithShort)

export interface IAbstractPageListColumn<T extends AAM> extends Omit<ColumnType<T>,
	'render'
	| 'key'
	| 'sorter'> {
	index?: number;
	name?: string;
	sortBy?: ModelSortName<T>;
	title: string | React.ReactNode;
}

export interface IPageListColumnRender<T extends AAM> extends IAbstractPageListColumn<T> {
	cell?: never;
	render: (model: T, v) => React.ReactNode;
}

export interface IPageListColumnCell<T extends AAM> extends IAbstractPageListColumn<T> {
	cell: (model: T, pageList: PageList | null) => ICellProps;
	render?: never;
}

export type PageListColumn<T extends AAM> = IPageListColumnRender<T> | IPageListColumnCell<T>;

interface ICellProps {
	empty?: boolean;
	link?: string;
	loading?: boolean;
	nowrap?: boolean;
	style?: CSSProperties;
	type?: ValueType;
	value: React.ReactNode | Moment;
}

const Cell = observer((props: { getOptions: () => ICellProps; }) => {
	const opts = props.getOptions();

	return (
		<div className="PageListCell" style={{ whiteSpace: opts.nowrap ? 'nowrap' : undefined, ...opts.style }}>
			<Value empty={opts.empty} link={opts.link} loading={opts.loading} type={opts.type} value={opts.value} />
		</div>
	);
});

const CellRender = observer((props: { renderContent: () => React.ReactNode }) => (
	<div className="PageListCell">
		{props.renderContent()}
	</div>
));

class ListCollectionManager {
	private _lists: ListCollection<AAM>[] = [];

	public filterByModel = (model: ModelClass<AAM>) => this._lists.filter(list => list.model === model);

	public push = (list: ListCollection<AAM>) => this._lists.push(list);

	public reloadAsync = async (model: ModelClass<AAM>) => {
		const items = this.filterByModel(model);

		console.log('ListCollectionManager', 'reload', `${items.length} collection${items.length > 1 ? 's' : ''}`);

		await Promise.all(items.map(list => list.reloadAsync()));
	};

	public remove = (list: ListCollection<AAM>) => this._lists = this._lists.filter(item => item !== list);
}

export const listCollectionManager = new ListCollectionManager();

export const HIDDEN_COLUMNS_STORAGE_KEY = 'LIST_HIDDEN_COLUMNS';

@observer
export default class ListCollection<T extends AAM> extends React.Component<IPageListCollectionProps<T>> {

	public refList = React.createRef<PageList>();
	public reloadOnDispose;

	public state: { hiddenColumns: number[]; };

	public constructor(props) {
		super(props);

		const globalHiddenColumns = JSON.parse(localStorage.getItem(HIDDEN_COLUMNS_STORAGE_KEY) || '{}');
		this.state = { hiddenColumns: globalHiddenColumns[this._storageKey] || [] };
	}

	public componentDidMount() {
		const { collection, reloadOn } = this.props;

		listCollectionManager.push(this as unknown as ListCollection<AAM>);

		if (reloadOn) {
			this.reloadOnDispose = reaction(() => {
				// Attention, il est important d'utiliser "this.props.reloadOn" et pas juste "reloadOn"
				return !!this.props.reloadOn && this.props.reloadOn(collection);
			}, value => {
				if (collection.isLoaded && !collection.isLoading && value) {
					console.log('Reload ListCollection reaction');

					this.refList.current?.fetch();
				}
			});
		}
	}

	public componentWillUnmount() {
		const { reloadOn } = this.props;

		listCollectionManager.remove(this as unknown as ListCollection<AAM>);

		if (reloadOn) {
			this.reloadOnDispose();
		}
	}

	public getReloadButton() {
		return (
			<ButtonEllipsis
				actions={[{ icon: <ReloadOutlined />, label: 'Actualiser', onClick: this.refList.current?.fetch }]}
			/>
		);
	}

	public getSettingsButton() {
		const { hiddenColumns } = this.state;

		return (
			<ButtonEllipsis
				actions={[
					...this._getPreparedColumns().map((c, index) => {
						const isVisible = !hiddenColumns.includes(index);

						return {
							label: (
								<View centerV gap={10} row>
									<View
										absL={0}
										absT={0}
										heightF
										onClick={e => {
											e.stopPropagation();
											this._toggleColumnIndex(index);
										}}
										widthF
									/>
									{isVisible ? <CheckSquareOutlined /> : <BorderOutlined />}
									{c.title}
								</View>
							),
						};
					}),
				]}
				icon={<SettingOutlined />}
			/>
		);
	}

	public reloadAsync = () => this.refList.current?.fetch();

	public render() {
		const customProps = { ...this.props } as IPageListCollectionProps<T>;

		const {
			collection,
			defaultSortName,
			disableFetchOnMount,
			displayCountFilters,
			hideShortFilters,
			loading,
			saveDisabled,
			sidebarFilters,
			sizeChangerHidden,
		} = customProps;

		const columns = this._getColumns();

		const sidebarFilterShorts = (sidebarFilters || []).filter(f => !!f.renderShort);
		const defaultFilters = { ...this.props.defaultFilters };

		if (sidebarFilters && this._displayedFilters.length) {
			customProps.renderSearchComponent = (data) => {
				return (
					<InputFilters
						filters={sidebarFilters as never}
						form={this.refList.current?.form as never}
						onChange={this.submitFilters}
						onClear={() => this.refList.current?.resetFilters()}
						pageList={this.refList.current as never}
						pageListData={data}
					/>
				);
			};

			customProps.renderSidebarComponent = (d) => (
				<>
					{this._renderFilters(d, false)}

					{!!this.props.renderSidebarComponent && this.props.renderSidebarComponent(d)}
				</>
			);

			sidebarFilters
				.filter(f => typeof f.defaultValue !== 'undefined')
				.forEach(f => defaultFilters[f.name] = f.defaultValue);
		}

		if (!hideShortFilters && sidebarFilterShorts.length) {
			customProps.renderSubTopComponent = (d) => (
				<>
					<Space wrap={true}>{this._renderFilters(d, true)}</Space>

					{!!this.props.renderSubTopComponent && this.props.renderSubTopComponent(d)}
				</>
			);
		}

		const sortable = Object.hasOwn(collection.model.prototype, 'position');

		return (
			<PageList
				name={saveDisabled ? undefined : this._storageKey}
				paginationDisabled={
					!(collection instanceof PagedCollection)
					|| sizeChangerHidden && collection.hasSinglePage
				}

				{...customProps}

				defaultFilters={defaultFilters}
				defaultSidebarOpened={false}
				defaultSortName={defaultSortName as string}
				disableFetchOnMount={disableFetchOnMount || collection.isLoaded || collection.isLoading}
				displayCountFilters={typeof displayCountFilters === 'undefined' ? true : displayCountFilters}
				fetch={this._fetchAsync}
				ref={this.refList}
				renderTopRightComponent={this.renderTopRightComponent}
				tableProps={{
					columns: columns.map(column => ({
						...column,
						key: column.sortBy as string,
						render: (v, model) => this._renderColumn(column, v, model),
						sorter: !!column.sortBy,
						title: typeof column.title === 'string' ? (
							<Typography.Text
								ellipsis
								style={{ color: 'inherit', maxWidth: '100%' }}
								title={column.title}
							>
								{column.title}
							</Typography.Text>
						) : column.title,
					})),
					dataSource: [...collection.models],
					loading: collection.isLoading || !collection.hasEverBeenLoading || loading,
					locale: {
						emptyText: <EmptyComponent collection={collection as unknown as AbstractApiCollection<AAM>} />,
					},
					onDrop: sortable ? async (dragIndex: number, hoverIndex: number) => {
						if (collection.models && collection.models[dragIndex]) {
							const model = collection.models[dragIndex];
							collection.setHasEverBeenLoading(false);
							await model.patch({ position: (collection.models[hoverIndex] as unknown as { position: number}).position });
							await model.collection?.list();
						}
					} : undefined,
					rowKey: model => model.id,
					sortable,

					...customProps.tableProps,
				}}
				total={collection instanceof PagedCollection ? collection.total : collection.length}
			/>
		);
	}

	public renderTopRightComponent = (data: PageListData, list: PageList) => {
		const { renderTopRightComponent, topRightActions } = this.props;

		return (
			<View gap={8} row>
				{!!renderTopRightComponent && renderTopRightComponent(data, list)}

				{!!topRightActions && <ButtonEllipsis actions={topRightActions(data, list)} type="primary" />}

				{this.getReloadButton()}
				{this.getSettingsButton()}
			</View>
		);
	};

	public submitFilters = () => this.refList.current?.submitFilters();

	public static managerReloadAsync = (model: ModelClass<AAM>) => listCollectionManager.reloadAsync(model);

	public get model() {
		return this.props.collection.model;
	}

	private _fetchAsync = async (d: PageListData) => {
		const {
			beforeFetch,
			collection,
			keepFilters,
			keepSorts,
			onApplyFilters,
			onFetchSuccess,
			sidebarFilters,
		} = this.props;

		if (!collection.model.path) {
			return;
		}

		if (onApplyFilters) {
			onApplyFilters();
		}

		const filters = { ...d.filters };

		if (!keepFilters) {
			collection.clearFilters();
		}

		if (sidebarFilters) {
			await Promise.all(
				Object.entries(d.filters).map(async ([key, value]) => {
					const filter = sidebarFilters.find(f => f.name === key);

					if (filter) {
						if (
							(Array.isArray(value) && value.length) ||
							(typeof value === 'boolean') ||
							(typeof value === 'string' && value) ||
							(typeof value === 'number' && value)
						) {
							if (typeof filter.transform === 'function') {
								await filter.transform(value, collection, d.filters);

								delete filters[key];
							} else {
								filters[filter.transform as string] = value;

								if (filter.name !== filter.transform) {
									delete filters[key];
								}
							}
						}
					}
				}),
			);
		}

		Object.entries(filters).forEach(([key, value]) => collection.setFilter(key as never, value as never));

		if (filters.search === '') {
			collection.removeFilter('search');
		}

		if (!keepSorts) {
			collection.setSorts({ [d.orderBy]: d.orderWay });
		}

		if (collection instanceof PagedCollection) {
			collection.setPage(d.page).setItemsPerPage(d.pageSize);
		}

		if (beforeFetch) {
			const resBeforeFetch = await beforeFetch(collection, d);
			// On annule la requête dans le cas ou le beforeFetch retourne "false".
			if (resBeforeFetch === false) {
				// On marque la collection comme "chargée" même si la requête n'a pas été exécutée.
				collection.setIsLoaded(true);
				collection.setHasEverBeenLoading(true);
				return;
			}
		}

		if (!collection.isVirtualCollection) {
			await collection.list();
		}

		if (onFetchSuccess) {
			await onFetchSuccess(collection, d);
		}
	};

	private get _storageKey() {
		const { collection } = this.props;
		const currentRoute = routes.find(route => matchPath(location.pathname, route));
		return `${currentRoute?.path || location.pathname}_${collection.path}`;
	}

	private _getColumns = () => {
		const { hiddenColumns } = this.state;
		const { columnActions, hidden, mode } = this.props;
		const preparedColumns = this._getPreparedColumns();

		if (columnActions) {
			const pageList = this.refList.current as never;

			preparedColumns.push({
				align: 'right',
				render: m => <ButtonEllipsis actions={columnActions(m, pageList || {})} size="small" />,
				title: '',
				width: 60,
			});
		}

		const columns = preparedColumns.filter((_, index) => !hiddenColumns.includes(index));

		if (mode === 'vertical') {
			const columnsWithTitle = columns.filter(c => c.title);
			const columnsWithoutTitle = columns.filter(c => !c.title);
			const columnsWithoutTitleLeft = columnsWithoutTitle.filter(c => c.fixed === 'left');
			const columnsWithoutTitleRight = columnsWithoutTitle.filter(c => c.fixed !== 'left');

			return [
				...columnsWithoutTitleLeft,
				{
					render: (m, v) => (
						<GridInfo data={[
							columnsWithTitle
								.filter(c => !c.hidden)
								.map(c => ({
									hidden: hidden ? hidden(m) : false,
									label: c.title,
									value: this._renderColumn(c, v, m),
								})),
						]} />
					),
					title: '',
				},
				...columnsWithoutTitleRight,
			] as PageListColumn<T>[];
		}

		return columns;
	};

	private _getPreparedColumns() {
		const { collection, hideColumns } = this.props;

		// Si une des colonnes masquées n'existe pas > SENTRY
		const columnNames = this.props.columns.map(c => c.name);
		const columnTitles = this.props.columns.map(c => c.title);
		if (hideColumns?.some(n => !columnNames.includes(n) && !columnTitles.includes(n))) {
			const message = `ListCollection - hideColumns - not found - ${hideColumns} - ${collection.modelPath}`;
			console.error(message);
			Sentry.captureMessage(message);
		}

		return _sortBy(this.props.columns, column => column.index || -1)
			.filter(c => (
				!hideColumns
				|| (
					!hideColumns.includes(c.name || '')
					&& !hideColumns.includes(c.title?.toString() || '')
				)
			));
	}

	private _renderColumn = (column: PageListColumn<T>, v, model) => {
		if (column.render) {
			return <CellRender renderContent={() => column.render(model, v)} />;
		}
		return <Cell getOptions={() => column.cell(model, this.refList.current)} />;
	};

	private _renderFilters = (d: PageListData, short = false) => {
		const filters = this._displayedFilters.filter(f => short ? !!f.renderShort : !!f.render);

		return filters.map(sidebarFilter => {
			let Comp: React.ReactNode = null;

			if (short && sidebarFilter.renderShort) {
				const render = sidebarFilter.renderShort === true ? sidebarFilter.render : sidebarFilter.renderShort;

				Comp = React.cloneElement((render ? render(d) : null) as never, {
					form: undefined,
					onChange: () => this.submitFilters(),
					size: 'middle',
				});
			} else if (sidebarFilter.render) {
				Comp = React.cloneElement((sidebarFilter.render ? sidebarFilter.render(d) : null) as never, {
					form: undefined,
				});
			}

			return (
				<Form.Item
					className={sidebarFilter.name}
					key={sidebarFilter.name}
					label={short ? undefined : sidebarFilter.label}
					name={sidebarFilter.name}
					style={short ? {} : {}}
				>
					{Comp}
				</Form.Item>
			);
		});
	};

	private _toggleColumnIndex(index: number) {
		const { hiddenColumns } = this.state;
		const isVisible = !hiddenColumns.includes(index);

		const newHiddenColumns = isVisible ? [index, ...hiddenColumns] : hiddenColumns.filter(v => v !== index);

		this.setState({ hiddenColumns: newHiddenColumns });

		const prevSave = JSON.parse(localStorage.getItem(this._storageKey) || '{}');

		localStorage.setItem(HIDDEN_COLUMNS_STORAGE_KEY, JSON.stringify(
			{ ...prevSave, [this._storageKey]: newHiddenColumns },
		));
	}

	private get _displayedFilters() {
		const { hideFilters } = this.props;

		const sidebarFilters = this.props.sidebarFilters || [];

		// Si un des filtres masqués n'existe pas > SENTRY
		const filterNames = sidebarFilters.map(f => f.name);
		const filterLabels = sidebarFilters.map(f => f.label);
		if (hideFilters !== true && hideFilters?.some(n => !filterNames.includes(n) && !filterLabels.includes(n))) {
			const message = `ListCollection - hideColumns - not found - ${hideFilters}`;
			console.error(message);
			Sentry.captureMessage(message);
		}

		return sidebarFilters.filter(sidebarFilter => {
			return !(
				hideFilters === true
				|| (
					Array.isArray(hideFilters)
					&& (
						hideFilters.includes(sidebarFilter.name)
						|| hideFilters.includes(sidebarFilter.label || '')
					)
				)
			);
		});
	}
}

export const createListComponent = <T extends AAM>(defaultProps: IPageListCollectionProps<T>) => {
	return class CreateListCollection extends React.Component<Partial<IPageListCollectionProps<T>>> {
		public refList = React.createRef<ListCollection<T>>();

		public constructor(props: Partial<IPageListCollectionProps<T>>) {
			super(props);

			defaultProps.collection.clear();
		}

		public fetch() {
			return this.refList.current?.refList.current?.fetch();
		}

		public render() {
			const mergedProps = _merge({}, defaultProps, this.props);

			const onFetchSuccess = async (coll: AbstractApiCollection<T>, pageListData: PageListData) => {
				if (defaultProps.onFetchSuccess) {
					await defaultProps.onFetchSuccess(coll, pageListData);
				}

				if (this.props.onFetchSuccess) {
					await this.props.onFetchSuccess(coll, pageListData);
				}
			};

			const beforeFetch = async (coll: AbstractApiCollection<T>, pageListData: PageListData) => {
				if (defaultProps.beforeFetch) {
					await defaultProps.beforeFetch(coll, pageListData);
				}

				if (this.props.beforeFetch) {
					await this.props.beforeFetch(coll, pageListData);
				}
			};

			return (
				<ListCollection
					ref={this.refList}
					{...mergedProps}
					beforeFetch={beforeFetch}
					columns={[...defaultProps.columns, ...(this.props.columns || [])]}
					onFetchSuccess={onFetchSuccess}
					reloadOn={mergedProps.reloadOn}
				/>
			);
		}
	};
};

const EmptyComponent = observer((props: { collection: AbstractApiCollection<AAM> }) => {
	const { collection } = props;
	const modelClass = collection.model as unknown as typeof AAM;

	const hasName = modelClass.staticLabel !== modelClass.prototype.modelName;

	const text = hasName ?
		`Aucun${modelClass.isStaticLabelMasculine ? '' : 'e'} ${modelClass.staticLabel.toLowerCase()}` : undefined;

	const pluralName = hasName ? modelClass.pluralStaticLabel.toLowerCase() : 'données';

	if (collection.isFailed) {
		return <Result status="error" title={`Erreur lors de la récupération des ${pluralName}`} />;
	}

	return (
		<Empty description={text} />
	);
});
