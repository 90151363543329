import doc from 'decorators/doc';
import model from 'decorators/model';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Statut de facture')
@model.urnResource('invoice_status')
@doc.path('/invoice_statuses/{?id}')
export default class InvoiceStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'label': string;
		'reference': InvoiceStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label:string;
	@doc.number declare position:number;
	@doc.string declare reference:InvoiceStatusReference;

	public get color() {
		switch (this.reference) {
			case 'paid':
				return 'green';
			case 'to_pay':
				return 'orange';
			case 'long_delay_in_payment':
				return 'red';
		}
	}
}