import { InfoCircleOutlined } from '@ant-design/icons';
import Hover                  from 'components/Hover';
import { HoverProps }         from 'components/Hover';
import Tooltip                from 'components/Tooltip';
import React                  from 'react';
import { appStore }           from 'stores/index';

export type HelpIconProps = {
	onHover?: HoverProps['onHover'];
	text?: React.ReactNode;
}

export default function HelpIcon(props: HelpIconProps) {
	const tooltip = (
		<Tooltip title={props.text}>
			<InfoCircleOutlined style={{ color: appStore.primaryColor }} />
		</Tooltip>
	);

	if (props.onHover) {
		return <Hover onHover={props.onHover}>{tooltip}</Hover>;
	}

	return tooltip;
}