import InvoiceModel           from 'Models/invoice/InvoiceModel';
import ItemDisplayModeModel   from 'Models/invoice/ItemDisplayModeModel';
import SourceModel            from 'Models/invoice/SourceModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoiceItemGroup')
@model.urnResource('invoice_item_group')
@doc.path('/invoice_item_groups/{?id}')
export default class InvoiceItemGroupModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'invoice': id;
		'sourceUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare description: string;
	@doc.model(InvoiceModel) declare invoice: InvoiceModel;
	@doc.model(ItemDisplayModeModel) declare itemDisplayMode: ItemDisplayModeModel;
	@doc.string declare label: string;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	
	@computed
	public get invoiceId(): id {
		return this.get('invoice.id');
	}
}