import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('invoice_notification_status')
@doc.path('/invoice_notification_statuses/{?id}')
export default class InvoiceNotificationStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': InvoiceNotificationStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InvoiceNotificationStatusReference;

	public get color() {
		if (this.reference === 'to_do') {
			return 'red';
		}
		return 'green';
	}
}