if (!window.FRONT_CONFIG) {
	window.FRONT_CONFIG = {
		APP_ENV: 'local',
		INSEE_SIREN_API_KEY: 'a42940bb-63a3-43ad-a940-bb63a3e3ad78',
		// SENTRY_DSN_BACKOFFICE: 'https://37a79e2ada9d4730a418adc242db12f7@o240489.ingest.sentry.io/5881198',
		SENTRY_DSN_BACKOFFICE: '',
		// SENTRY_DSN_FRONT_CONTACT: 'https://873d1ed5b2494b82a5ad7d32f38bb391@o240489.ingest.sentry.io/5624825',
		SENTRY_DSN_FRONT_CONTACT: '',
	};
}

const FrontConfig = window.FRONT_CONFIG;

export default FrontConfig;
