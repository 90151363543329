import { DownloadOutlined, WarningOutlined }   from '@ant-design/icons';
import { SettingOutlined }                     from '@ant-design/icons';
import { ShoppingCartOutlined }                from '@ant-design/icons';
import { MessageOutlined }                     from '@ant-design/icons';
import { HomeOutlined }                        from '@ant-design/icons';
import { EuroOutlined }                        from '@ant-design/icons';
import { CopyOutlined }                        from '@ant-design/icons';
import { ContactsOutlined }                    from '@ant-design/icons';
import { CarOutlined }                         from '@ant-design/icons';
import { CalendarOutlined }                    from '@ant-design/icons';
import PlanningModel                           from 'Models/intervention/PlanningModel';
import NotificationModel                       from 'Models/notification/NotificationModel';
import ContractModel                           from 'Models/sales/ContractModel';
import FrameworkAgreementIterationModel        from 'Models/sales/FrameworkAgreementIterationModel';
import QuotationModel                          from 'Models/sales/QuotationModel';
import ContactModel                            from 'Models/supplier/ContactModel';
import OrderPartModel                          from 'Models/supplier/OrderPartModel';
import PeriodModel                             from 'Models/supplier/PeriodModel';
import SupplierCompanyModel                    from 'Models/supplier/SupplierCompanyModel';
import SupplierEnterpriseModel                 from 'Models/supplier/SupplierEnterpriseModel';
import DamageModel                             from 'Models/vehicle/DamageModel';
import MaintenanceDutyModel                    from 'Models/vehicle/MaintenanceDutyModel';
import VehicleModel                            from 'Models/vehicle/VehicleModel';
import ViolationTicketModel                    from 'Models/vehicle/ViolationTicketModel';
import { ItemType }                            from 'antd/lib/menu/interface';
import { MenuItemType }                        from 'antd/lib/menu/interface';
import { SubMenuType }                         from 'antd/lib/menu/interface';
import Menu                                    from 'antd/lib/menu';
import IconSvg                                 from 'components/IconSvg/IconSvg';
import * as H                                  from 'history';
import orgSvg                                  from 'icons/organisation.svg';
import _uniqueId                               from 'lodash/uniqueId';
import { observer }                            from 'mobx-react';
import { NavLink }                             from 'react-router-dom';
import { matchPath }                           from 'react-router';
import React                                   from 'react';
import routes                                  from 'routes';
import { appStore }                            from 'stores';
import navigate                                from 'tools/navigate';

interface INavigationProps {
	location: H.Location;
}

const menuPlanning = () => {
	return appStore.collectionPlanning.map(p => getItem({ label: p.label }, p.pathToView));
};

const getItem = (item: Partial<MenuItemType | SubMenuType>, path?: string): ItemType => ({
	...item,
	key: path || _uniqueId(),
	label: path ? <NavLink to={path}><>{item.label}</></NavLink> : <div>{item.label}</div>,
} as ItemType);

const getMenuItems: () => ItemType[] = () => [
	getItem({ icon: <HomeOutlined />, label: 'Accueil' }, navigate.toHome()),
	getItem({
		children: [
			getItem({ label: 'Entreprises' }, navigate.toEnterprisePartitionList()),
			getItem({ label: 'Établissements' }, navigate.toClientList()),
			getItem({ label: 'Sites' }, navigate.toTaskZoneList()),
			getItem({ label: 'Contacts entreprises' }, navigate.toContactList()),
		],
		icon: <ContactsOutlined />,
		label: 'Clients',
	}),
	getItem({
		children: [
			getItem({ label: ContractModel.pluralStaticLabel }, navigate.toContractIterationList()),
			getItem({ label: `Groupe de ${ContractModel.pluralStaticLabel}` }, navigate.toContractList()),
			getItem({ label: QuotationModel.pluralStaticLabel }, navigate.toQuotationList()),
			getItem({ label: `Synthèse des ${ContractModel.pluralStaticLabel}` }, navigate.toContractIterationStatisticList()),
			getItem({ label: `Synthèse des ${QuotationModel.pluralStaticLabel}` }, navigate.toQuotationStatisticList()),
			getItem({ label: FrameworkAgreementIterationModel.pluralStaticLabel }, FrameworkAgreementIterationModel.pathToList),
		],
		icon: <CopyOutlined />,
		label: `${ContractModel.pluralStaticLabel} / ${QuotationModel.pluralStaticLabel} / Versions`,
	}),
	getItem({
		children: [
			...menuPlanning(),
			...(appStore.collectionPlanning.length ? [{ type: 'divider' as never }] : []),
			getItem({ label: 'Planning techniciens' }, navigate.toResourcePlanning()),
			getItem({ label: 'Interventions' }, navigate.toInterventionList()),
			getItem({ label: 'Groupes d\'interventions' }, navigate.toInterventionGroupList()),
			getItem({ label: 'Opérations' }, navigate.toTaskOperationList()),
			getItem({ label: 'Tâches' }, navigate.toTaskList()),
			getItem({ label: 'Urgences' }, navigate.toQuotationRequestList()),
			getItem({ children: [
				getItem({ label: 'Synthèse des passages des contrats' }, navigate.toContractIterationItemEventItemActivity()),
				getItem({ label: 'Synthèse des passages des devis' }, navigate.toQuotationItemEventItemActivity()),
			], label: 'Synthèse des passages' }),
			getItem({ label: 'Suivi des interventions' }, navigate.toInterventionStatisticList()),
			getItem({ label: 'Rapports d\'interventions' }, navigate.toReportList()),
		],
		icon: <CalendarOutlined />,
		label: 'Planning des services',
	}),
	getItem({
		children: [
			getItem({ label: `Tableau de bord des achats` }, navigate.toSupplierServiceDashboard()),
			getItem({ label: OrderPartModel.pluralStaticLabel }, OrderPartModel.pathToList),
			getItem({ label: PeriodModel.pluralStaticLabel }, PeriodModel.pathToList),
			getItem({ label: SupplierEnterpriseModel.pluralStaticLabel }, SupplierEnterpriseModel.pathToList),
			getItem({ label: SupplierCompanyModel.pluralStaticLabel }, SupplierCompanyModel.pathToList),
			getItem({ label: ContactModel.pluralStaticLabel }, navigate.toSupplierContactList()),
		],
		icon: <ShoppingCartOutlined />,
		label: `Commandes / ${SupplierEnterpriseModel.pluralStaticLabel}`,
	}),
	getItem({
		children: [
			getItem({ label: `Tableau de bord` }, navigate.toVehicleServiceDashboard()),
			getItem({ label: VehicleModel.pluralStaticLabel }, VehicleModel.pathToList),
			getItem({ label: ViolationTicketModel.pluralStaticLabel }, ViolationTicketModel.pathToList),
			getItem({ label: DamageModel.pluralStaticLabel }, DamageModel.pathToList),
			getItem({ label: MaintenanceDutyModel.pluralStaticLabel }, MaintenanceDutyModel.pathToList),
			getItem({ label: 'Salariés' }, navigate.toStaffMemberList()),
			getItem({ label: 'Synthèse des équipements' }, navigate.toVehicleRiskList()),
		],
		icon: <CarOutlined />,
		label: 'Gestion parc auto. / Salariés',
	}),
	getItem({
		children: [
			getItem({ label: 'Factures' }, navigate.toInvoiceList()),
			getItem({ label: 'Suivi de gestion' }, navigate.toBillingSummary()),
			getItem({ label: 'Périodes de facturation' }, navigate.toBillingPeriodList()),
			getItem({ label: 'Balance âgée clients' }, navigate.toAgedTrialBalanceList()),
			getItem({ label: 'Suivi facturation' }, navigate.toInvoiceItemList()),
			getItem({ label: 'Facture à émettre' }, navigate.toInvoiceNotificationList())
		],
		icon: <EuroOutlined />,
		label: 'Facturation',
	}),
	getItem({
		children: [
			getItem({ label: 'Anomalies administratives (contrats)' }, navigate.toAlertContractAdministrativeList()),
			getItem({ label: 'Anomalies administratives (devis)' }, navigate.toAlertQuotationAdministrativeList()),
			getItem({ label: `Anomalies d'exploitation` }, navigate.toAlertOperationalList()),
		],
		icon: <WarningOutlined />,
		label: 'Anomalies',
	}),
	getItem({ icon: <MessageOutlined />, label: NotificationModel.pluralStaticLabel }, NotificationModel.pathToList),
	getItem({
		children: [
			getItem({ label: `Types d'opération` }, navigate.toOperationList()),
			getItem({ label: `Types de prestation` }, navigate.toActivityList()),
			getItem({ label: `Propriétés` }, navigate.toPropertyList()),
			getItem({ label: `Informations à collecter` }, navigate.toCollectableList()),
			getItem({ label: PlanningModel.pluralStaticLabel }, PlanningModel.pathToList),
		],
		icon: <SettingOutlined />,
		label: 'Paramètres',
	}),
];

const getMenuItemsSettings: () => ItemType[] = () => [
	getItem({
		children: [
			getItem({ label: 'Annuaire général des entreprises' }, navigate.toEnterpriseList()),
			getItem({ label: 'Annuaire général des établissements' }, navigate.toCompanyList()),
			getItem({ label: 'Annuaire général des fournisseurs' }, navigate.toSupplierEnterpriseDirectoryList()),
		],
		icon: <IconSvg color={'#747474'} svg={orgSvg} />,
		label: 'Annuaires',
	}),
];

export const getAllMenuItems = () => [
	...getMenuItems(),
	{ children: getMenuItemsSettings() },
	getItem({ icon: <DownloadOutlined />, label: 'Téléchargements' }, navigate.toFiles()),
];

@observer
export default class Navigation extends React.Component<INavigationProps> {

	public render() {
		const selectedKeys = [];
		this._getCurrentSelectedKeys(selectedKeys, routes);

		return (
			<div className="Navigation__Container">
				<Menu
					className="Navigation__Menu"
					defaultOpenKeys={['subNav']}
					id="Navigation__Menu"
					items={getMenuItems()}
					mode="inline"
					selectedKeys={selectedKeys}
					theme="light"
				/>

				<Menu
					className="Navigation__MenuBottom"
					defaultOpenKeys={['subNav']}
					id="Navigation__MenuBottom"
					items={getMenuItemsSettings()}
					mode="inline"
					selectedKeys={selectedKeys}
					theme="light"
				/>
			</div>
		);
	}

	private _getCurrentSelectedKeys(matchedRoutes, routes) {
		for (let i = 0; i < routes.length; i++) {
			const route = routes[i];
			const matchConfig = { exact: route.exact, path: route.path };

			if (route.path === '/') {
				matchConfig.exact = true;
			}

			if (
				matchPath(this.props.location.pathname, matchConfig)
				&& route.path !== '/*'
			) {
				matchedRoutes.push(this.props.location.pathname);
			}

			if (route.routes) {
				this._getCurrentSelectedKeys(matchedRoutes, route.routes);
			}
		}
	}
}
