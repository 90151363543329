import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration()
@model.staticLabel(`Mode d'envoi`)
@model.urnResource('channel_notification')
@doc.path('/channel_notifications/{?id}')
export default class ChannelNotificationModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': ChannelNotificationReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: ChannelNotificationReference;
}
