import { PagedCollection }                from 'Collections/PagedCollection';
import ClientModel                        from 'Models/directory/ClientModel';
import CompanyAddressModel                from 'Models/directory/CompanyAddressModel';
import CompanyModel                       from 'Models/directory/CompanyModel';
import ContactModel                       from 'Models/directory/ContactModel';
import EnterprisePartitionModel           from 'Models/directory/EnterprisePartitionModel';
import ContactTypeModel                   from 'Models/intervention/ContactTypeModel';
import OperationCategoryModel             from 'Models/intervention/OperationCategoryModel';
import OwnerModel                         from 'Models/intervention/OwnerModel';
import ResourceDocumentTypeModel          from 'Models/intervention/ResourceDocumentTypeModel';
import TaskZoneModel                      from 'Models/intervention/TaskZoneModel';
import TaskZoneResourceDocumentTypeModel  from 'Models/intervention/TaskZoneResourceDocumentTypeModel';
import ZoneContactModel                   from 'Models/intervention/ZoneContactModel';
import ContractIterationClosingModel      from 'Models/sales/ContractIterationClosingModel';
import ContractIterationItemActivityModel from 'Models/sales/ContractIterationItemActivityModel';
import ContractIterationItemModel         from 'Models/sales/ContractIterationItemModel';
import ContractIterationModel             from 'Models/sales/ContractIterationModel';
import ContractIterationStatusModel       from 'Models/sales/ContractIterationStatusModel';
import ContractModel                      from 'Models/sales/ContractModel';
import OwnerBlockRequestTargetModel       from 'Models/sales/OwnerBlockRequestTargetModel';
import QuotationClosingModel              from 'Models/sales/QuotationClosingModel';
import QuotationItemActivityModel         from 'Models/sales/QuotationItemActivityModel';
import QuotationItemModel                 from 'Models/sales/QuotationItemModel';
import QuotationModel                     from 'Models/sales/QuotationModel';
import QuotationStatusModel               from 'Models/sales/QuotationStatusModel';
import { ApiCollection }                  from 'modelx/collections/ApiCollection';
import { appStore }                       from 'stores';
import ConfigProxy                        from 'tools/ConfigProxy';
import AbstractModelXStore                from './AbstractModelXStore';

export default class TaskZoneDashboardStore extends AbstractModelXStore {

	public client = new ClientModel();
	public company = new CompanyModel();
	public companyAddress = new CompanyAddressModel();
	public contactCollection = new ApiCollection(ContactModel);
	public contactTypeCollection = new ApiCollection(ContactTypeModel);
	public operationCategoryCollection = new ApiCollection(OperationCategoryModel);
	public owner = new OwnerModel();
	public pagedCollOwnerBlockRequestTarget = new PagedCollection(OwnerBlockRequestTargetModel).setItemsPerPage(1);
	public resourceDocumentTypeCollection = new ApiCollection(ResourceDocumentTypeModel);
	public taskZoneResourceDocumentTypeCollection = new ApiCollection(TaskZoneResourceDocumentTypeModel);
	public zoneContactCollection = new ApiCollection(ZoneContactModel);

	// eslint-disable-next-line @typescript-eslint/member-ordering
	public contractCollection = new ApiCollection(ContractModel);
	public contractIterationClosingCollection = new ApiCollection(ContractIterationClosingModel);
	public contractIterationItemActivityCollection = new ApiCollection(ContractIterationItemActivityModel);
	public contractIterationItemCollection = new ApiCollection(ContractIterationItemModel);
	public contractIterationPagedCollection = new PagedCollection(ContractIterationModel);
	public contractIterationStatusCollection = new ApiCollection(ContractIterationStatusModel);

	public quotationClosingCollection = new ApiCollection(QuotationClosingModel);
	public quotationItemActivityCollection = new ApiCollection(QuotationItemActivityModel);
	public quotationItemCollection = new ApiCollection(QuotationItemModel);
	public quotationPagedCollection = new PagedCollection(QuotationModel);
	public quotationStatusCollection = new ApiCollection(QuotationStatusModel);

	public loadContractAndQuotationCollection = (taskZoneUrn: string, page = 1, itemsPerPage = 10) => {
		return Promise.all([
			this.loadContractPagedCollection(taskZoneUrn, page, itemsPerPage),
			this.loadQuotationPagedCollection(taskZoneUrn, page, itemsPerPage),
		]);
	};

	public loadContractPagedCollection = (taskZoneUrn: string, page = 1, itemsPerPage = 10) => {
		this.contractIterationPagedCollection.setPage(page);
		this.contractIterationPagedCollection.setItemsPerPage(itemsPerPage);

		if (!taskZoneUrn) {
			throw new Error('taskZoneUrn is required');
		}

		return Promise.all([
			this.contractIterationPagedCollection
				.setFilters({
					'archived': '0',
					'contract.owner.ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
					'items.itemActivities.locationUrn': taskZoneUrn,
				})
				.setSort('createdAt', false)
				.list()
				.then(async () => {
					await Promise.all([
						this.contractCollection.listByFromCollection(this.contractIterationPagedCollection, 'contractId', 'id'),
						this.contractIterationItemCollection.listByFromCollection(this.contractIterationPagedCollection, 'id', 'contractIteration').then(() => {
							return this.contractIterationItemActivityCollection.listByFromCollection(this.contractIterationItemCollection, 'id', 'contractIterationItem');
						}),
					]);
				}),

			this.contractIterationStatusCollection.list({ cache: 3600 }),
		]);
	};

	public loadQuotationPagedCollection = (taskZoneUrn: string, page = 1, itemsPerPage = 10) => {
		this.quotationPagedCollection.setPage(page);
		this.quotationPagedCollection.setItemsPerPage(itemsPerPage);

		if (!taskZoneUrn) {
			throw new Error('taskZoneUrn is required');
		}

		return Promise.all([
			this.quotationPagedCollection
				.setFilters({
					'archived': '0',
					'exists[quotationRequests]': '0',
					'items.itemActivities.locationUrn': taskZoneUrn,
					'owner.ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
				})
				.setSort('createdAt', false)
				.list()
				.then(async () => {
					await this.quotationClosingCollection
						.listByFromCollection(this.quotationPagedCollection, 'id', 'quotation');

					await this.quotationItemCollection
						.listByFromCollection(this.quotationPagedCollection, 'id', 'quotation');

					await this.quotationItemActivityCollection
						.listByFromCollection(this.quotationItemCollection, 'id', 'quotationItem');
				}),

			this.quotationStatusCollection.list(),
		]);
	};

	public async fetchZoneContact(taskZone: TaskZoneModel, enterprisePartition: EnterprisePartitionModel) {
		await Promise.all([
			this.contactCollection
				.setSort('globalContact.firstName')
				.setFilters({ enterprisePartition: enterprisePartition.id })
				.list(),

			this.zoneContactCollection
				.setSort('position')
				.setFilters({ taskZone: taskZone.id })
				.list()
				.then(async () => {
					await Promise.all([
						this.contractIterationClosingCollection
							.listByFromCollection(this.contractIterationPagedCollection, 'id', 'contractIteration'),

						this.contactTypeCollection
							.setSort('label')
							.listByFromCollection(this.zoneContactCollection, 'contactTypeId'),

						this.operationCategoryCollection
							.listByFromCollection(this.zoneContactCollection, 'operationCategoryId'),
					]);
				}),
		]);
	}

	public async initAsync(
		taskZone: TaskZoneModel,
		enterprisePartition: EnterprisePartitionModel,
		company: CompanyModel,
	) {
		this.clear();

		await Promise.all([
			new ApiCollection(CompanyAddressModel)
				.setFilters({ company: company.id })
				.list()
				.then(coll => this.companyAddress.set(coll.first()?.attributes)),

			this.loadContractAndQuotationCollection(taskZone.urn, 1, 10),

			this.taskZoneResourceDocumentTypeCollection.setFilter('taskZone', taskZone.id).list(),

			this.pagedCollOwnerBlockRequestTarget.listBy([taskZone.urn], 'targetUrn'),

			this.resourceDocumentTypeCollection
				.setSort('position')
				.setFilter('partitionUrn', ConfigProxy.get('PARTITION_URN'))
				.list({ cache: 3600 }),

			this.fetchZoneContact(taskZone, enterprisePartition),
		]);
	}
}
