import ChannelNotificationModel       from 'Models/invoice/ChannelNotificationModel';
import InvoiceNotificationStatusModel from 'Models/invoice/InvoiceNotificationStatusModel';
import NotificationTypeModel          from 'Models/invoice/NotificationTypeModel';
import PartitionModel                 from 'Models/partition/PartitionModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Blamable }                   from 'helpers/traits';
import { Timestampable }              from 'helpers/traits';
import InvoicePrivateApiModel         from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoiceNotification')
@model.urnResource('invoice_notification')
@doc.path('/invoice_notifications/{?id}')
export default class InvoiceNotificationModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'channelNotification': id;
		'channelNotification.reference': ChannelNotificationReference;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'invoiceNotificationInvoices.invoice': id;
		'invoiceNotificationStatus': id;
		'invoiceNotificationStatus.reference': InvoiceNotificationStatusReference;
		'notificationType': id;
		'notificationType.reference': NotificationTypeReference;
		'partitionUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(ChannelNotificationModel) declare channelNotification: ChannelNotificationModel;
	@doc.string declare customMessage:string;
	@doc.model(InvoiceNotificationStatusModel) declare invoiceNotificationStatus: InvoiceNotificationStatusModel;
	@doc.model(NotificationTypeModel) declare notificationType: NotificationTypeModel;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
}